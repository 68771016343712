import React, { FC } from 'react'

import { formatClassList } from '@bscs-dev-team/bscs-design-system-common'
import { Button, PillBadge } from '@bscs-dev-team/bscs-design-system-core'


// setActiveFilters: (value: any[]) => void | ((func: (current: any[]) => any[]) => void)
type FilterByRowProps = {
  activeFilters: string[],
  categoryFilters?: string[],
  setActiveFilters: (value: string[]) => void
}

type RemoveXIconProps = {
  onClick: () => void
}

const PILL_BADGE: string = `
  bg-bscs-gray-300
  filter-pill
  flex
  my-2
  mr-2
  overflow-hidden
  text-bscs-gray-800
  relative
  whitespace-no-wrap
`

const PILL_WRAPPER: string = `
  flex
  flex-wrap
`

const REMOVE_ICON: string = `
  fa-times-circle
  far
  remove-filter
  self-center
  text-bscs-gray-100
  text-lg
`

const REMOVE_SPAN: string = `
  absolute
  bg-bscs-gray-600
  flex
  h-full
  items-center
  left-0
  rounded-l-full
  top-0
`

const removeFilter = (activeFilters: string[], filter: string): string[] => {
  const filterIndex = activeFilters.indexOf(filter)

  if (filterIndex === -1) {
    return activeFilters
  }

  // make copy of activeFilters
  let updatedFilters = activeFilters.slice(0)

  // remove 1 element at updated_filters[index]
  updatedFilters.splice(filterIndex, 1)

  return updatedFilters
}

const RemoveXIcon: FC<RemoveXIconProps> = ({onClick}: RemoveXIconProps) => {
  const formattedRemoveIcon: string = formatClassList(REMOVE_ICON)
  const formattedRemoveSpan: string = formatClassList(REMOVE_SPAN)

  return (
    <span
      className={formattedRemoveSpan}
      onClick={onClick}
      style={{ width: '29px' }}
    >
      <i
        className={formattedRemoveIcon}
        style={{ marginLeft: '7px' }}
      />
    </span>
  )
}

const FilterByRow: FC<FilterByRowProps> = ({
  activeFilters,
  categoryFilters,
  setActiveFilters
}: FilterByRowProps) => {
  const formattedPillBadge: string = formatClassList(PILL_BADGE)
  const formattedPillWrapper: string = formatClassList(PILL_WRAPPER)

  return (
    <div className="sm:flex">
      <Button
        className='mr-2'
        size="sm"
        onClick={() => setActiveFilters([])}
        title='Clear Filters'
      >
        Clear Filters
      </Button>
      <div className={formattedPillWrapper}>
        {
          activeFilters.map((filter, index) => {
            if (categoryFilters && categoryFilters.indexOf(filter) > -1) {
              return (
                <PillBadge
                  className={formattedPillBadge}
                  key={`filter-${index}`}
                  style={{paddingLeft: '34px'}}
                >
                  <RemoveXIcon onClick={() => setActiveFilters(removeFilter(activeFilters, filter))} />
                  {filter}
                </PillBadge>
              )
            }

            return (
              <PillBadge
                className={formattedPillBadge}
                key={`filter-${index}`}
                style={{paddingLeft: '34px'}}
              >
                <RemoveXIcon onClick={() => setActiveFilters(removeFilter(activeFilters, filter))} />
                {filter}
              </PillBadge>
            )
          })
        }
      </div>
    </div>
  )
}

export default FilterByRow

export {
  removeFilter,
  RemoveXIcon
}

