import React, {
  FC,
  ReactNode,
  useState,
  useEffect
} from 'react'

import { SearchInputField } from '@bscs-dev-team/bscs-design-system-add-ons'


const showElem = (elem: HTMLElement): void => {
  elem.style.display = ''

  if (elem && elem.parentElement) {
    elem.parentElement.style.display = ''
  }
}

const hideElem = (elem: HTMLElement): void => {
  elem.style.display = 'none'

  if (elem && elem.parentElement) {
    elem.parentElement.style.display = 'none'
  }
}

const format = (value: string[] | string | number | null): string => {
  if (!value) {
    return ""
  }

  const formatted_string: string =
    String(value)
      .toLowerCase()
      .replace(/,/g, '')

  return formatted_string
}

const handleDisplay = (obj: Record<string, string[]>, search_term: string | null, elem: HTMLElement): void => {
  for (let key in obj) {
    if (obj[key] !== null) {
      const search_item_sorted = format(obj[key])
      const search_term_sorted = format(search_term)

      if (search_item_sorted.includes(search_term_sorted)) {
        showElem(elem)
        break
      }

      hideElem(elem)
    }
  }
}

const handleFilter = (value: string, search_items: any) => {
  // e.preventDefault()

  const search_term: string = value

  for (let i: number = 0; i < search_items.length; i++) {
    const elem: HTMLElement | null = document.getElementById(search_items[i][0])

    if (!elem) {
      return
    }

    const values: (string|null|undefined)[] = ['reset', '', null, undefined]

    typeof search_term === 'string' && values.indexOf(search_term.toLowerCase()) !== -1
      ? showElem(elem)
      : handleDisplay(search_items[i][1], search_term, elem)
  }
}

const getSearchItems = (): void => {
  let search_items: any = []

  if (document) {
    const searchable_elements: NodeListOf<Element> = document.querySelectorAll('[data-filter]')

    for (let i: number = 0; i < searchable_elements.length; i++) {
      const id: string | null = searchable_elements[i].getAttribute('id')

      const dataFilter: string | null = searchable_elements[i].getAttribute('data-filter')

      if (typeof dataFilter === 'string') {
        const search_values: string = JSON.parse(dataFilter)

        search_items.push([id, search_values])
        continue
      }

      search_items.push([id, null])
    }
  }

  return search_items
}

const SearchBy: FC<ReactNode> = () => {
  const [search_items, setSearchItems] = useState<any>([])

  useEffect(() => {
    setSearchItems(getSearchItems())
  }, [])

  return (
    <SearchInputField
      id='search-by'
      label='Search Page'
      name='Search Page'
      setValue={(value: string) => handleFilter(value, search_items)}
    />
  )
}

export default SearchBy

export {
  showElem,
  hideElem,
  format,
  getSearchItems
}

