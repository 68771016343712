import React, { FC, useState } from 'react'
import { graphql, Link } from 'gatsby'

import { Frontmatter } from '../../templates/template-types'

import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ContentArea,
  PageTitle,
  PillBadge,
  TitleBorder,
  VerticalCard,
  VerticalCardBody
} from '@bscs-dev-team/bscs-design-system-core'

import FilterByDropdown from '../../components/filter-by-dropdown/filter-by-dropdown'
import FilterByRow from '../../components/filter-by-row/filter-by-row'
import Layout from '../../components/layout/layout'
import SEO from '../../components/seo'
import SearchBy from '../../components/search-by/search-by'


type Node = {
  excerpt: string,
  frontmatter: Frontmatter,
  id: string
}
type Edge = {
  node: Node
}

type AllMdx = {
  edges: Edge[]
}

type Data = {
  allMdx: AllMdx
}

type RDProgramsProps = {
  data: Data
}

type FilterItems = {
  areas_of_work: (string | string[] | boolean)[]
}

const RDPrograms: FC<RDProgramsProps> = ({ data }: RDProgramsProps) => {
  const programs: Edge[] = data.allMdx.edges

  const filterItems: FilterItems = {
    areas_of_work: [
      "Areas of Work",
      true,
      [
        "Instructional Materials Development",
        "Teacher Professional Learning",
        "Leadership Development",
        "Research"
      ]
    ]
  }

  const [activeFilters, setActiveFilters] = useState<string[]>([])

  return (
    <React.Fragment>
      <SEO
        title="Science Teachers R&D Programs Instructional Materials & Training"
        description="BSCS Science Learning is an independent nonprofit offering science teachers R&D programs, instructional materials & training across the US."
        canonicalUrl="https://bscs.org/our-work/rd-programs/"
      />
      <Layout>
        <section className="section">
          <div>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link className='inline-anchor' to='/'>Home</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                Our Work
              </BreadcrumbItem>
              <BreadcrumbItem>
                R&amp;D Programs
              </BreadcrumbItem>
            </Breadcrumb>
            <PageTitle>R&amp;D Programs</PageTitle>
            <TitleBorder />
            <p>Browse BSCS Science Learning's robust R&amp;D Programs and current projects below.</p>
          </div>
        </section>
        <section className="section">
          <div>
            <div className="sm:flex">
              <div className="py-2 pl-0 pr-2">
                <SearchBy />
              </div>
              <div className="py-2 pl-2 pr-0 ml-auto">
                <FilterByDropdown
                  items={filterItems}
                  activeFilters={activeFilters}
                  setActiveFilters={(activeFilters) => setActiveFilters(activeFilters)}
                />
              </div>
            </div>
            <FilterByRow
              activeFilters={activeFilters}
              setActiveFilters={(activeFilters) => setActiveFilters(activeFilters)}
            />
            <hr />
          </div>
        </section>
        <section className="section">
          <div>
            <div>
              <div>
                <h2>Featured Programs</h2>
                <hr className="mt-1 border-t border-bscs-gray-300" />
              </div>
            </div>
            <div className="flex flex-wrap mt-5 justify-center">
              {
                programs.map((edge, index) => {
                  const data_filter = JSON.parse(JSON.stringify(edge.node.frontmatter))
                  data_filter['excerpt'] = edge.node.excerpt

                  if (edge.node.frontmatter.sortOrder < 5) {
                    return (
                      <div
                        className="flex w-full md:w-1/2 xl:w-1/4 justify-center"
                        key={edge.node.id}
                      >
                        <VerticalCard
                          id={`resource-${index}`}
                          data-filter={JSON.stringify(data_filter)}
                          data-type={edge.node.frontmatter.type}
                          className="flex w-full"
                        >
                          <VerticalCardBody>
                            <h3>{edge.node.frontmatter.title}</h3>
                            <div>
                              <div className="mr-auto mb-3">
                                <div className="flex flex-wrap mb-3">
                                  {
                                    edge.node.frontmatter.areas.map((area: string, index: number) => {
                                      const variants: Record<string, string> = {
                                        "Teacher Professional Learning": "orange",
                                        "Instructional Materials Development": "blue",
                                        "Research": "green",
                                        "Leadership Development": "red"
                                      }
                                      return (
                                        <React.Fragment key={`${edge.node.id}-area-${index}`}>
                                          <PillBadge
                                            className="mt-1 mr-1"
                                            variant={variants[area]}
                                          >
                                            {area}
                                          </PillBadge>
                                        </React.Fragment>
                                      )
                                    })
                                  }
                                </div>
                              </div>
                              <p>
                                {edge.node.frontmatter.cardDescription}
                                {!edge.node.frontmatter.cardDescription && edge.node.excerpt}
                              </p>
                            </div>
                          </VerticalCardBody>
                          <div className="flex w-full items-end justify-end p-5">
                            <Link
                              to={`/our-work/rd-programs/${edge.node.frontmatter.title.replace(/\s/g, '-').replace(/[^a-zA-Z0-9-]/g, '').toLowerCase()}`}
                            >
                              <Button variant="indigo" title="Read More">Read More</Button>
                            </Link>
                          </div>
                        </VerticalCard>
                      </div>
                    )
                  }

                  return <React.Fragment key={`not-featured-${edge.node.id}`} />
                })
              }
            </div>
            <div>
              <h2>Current Projects</h2>
              <hr className="mt-1 border-t border-bscs-gray-300" />
            </div>
            <div className="justify-center mt-5">
              {
                programs.map((edge, index) => {
                const data_filter = JSON.parse(JSON.stringify(edge.node.frontmatter))
                data_filter['excerpt'] = edge.node.excerpt
                  if (edge.node.frontmatter.sortOrder >= 5) {
                    return (
                      <ContentArea
                        size="xl"
                        id="parent"
                         key={edge.node.id}
                      >
                        <div
                          id={`resource-${index}`}
                          data-filter={JSON.stringify(data_filter)}
                          data-type={edge.node.frontmatter.type}
                        >
                          <h3>{edge.node.frontmatter.title}</h3>
                          <div className="flex flex-wrap">
                            {
                              edge.node.frontmatter.areas.map((area: string, index: number) => {
                                const variants: Record<string, string> = {
                                  "Teacher Professional Learning": "orange",
                                  "Instructional Materials Development": "blue",
                                  "Research": "green",
                                  "Leadership Development": "red"
                                }

                                return (
                                  <PillBadge
                                    className="mt-1 mr-1"
                                    key={`${edge.node.id}-area-${index}`}
                                    variant={variants[area]}
                                  >
                                    {area}
                                  </PillBadge>
                                )
                              })
                            }
                          </div>
                          <p className="mb-lg-0">{!edge.node.frontmatter.cardDescription && edge.node.excerpt}</p>
                          <div className="flex">
                            <div className="p-2 ml-auto button-wrapper">
                              <Link
                                to={`/our-work/rd-programs/${edge.node.frontmatter.title.replace(/\s/g, '-').replace(/[^a-zA-Z0-9-]/g, '').toLowerCase()}`}
                              >
                                <Button variant="indigo" title="Read More">Read More</Button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </ContentArea>
                    )
                  }

                  return (
                    <React.Fragment key={`featured-${edge.node.id}`}></React.Fragment>
                  )
                })
              }
            </div>
          </div>
        </section>
      </Layout>
    </React.Fragment>
  )
}

export default RDPrograms

export const rdProgramsQuery = graphql`
  query rdProgramsQuery {
    allMdx(
      filter: {frontmatter: { status: {eq: "Ready"}, page: {eq: "rd-programs"}}}
      sort: {
        fields: [frontmatter___sortOrder, frontmatter___title],
        order: ASC
      }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 150)
          frontmatter {
            additionalTags,
            cardDescription,
            areas,
            date,
            sortOrder,
            title,
            page
          }
        }
      }
    }
  }
`
