import React, { FC, useEffect, useState } from 'react'

import { FormDropdownField } from '@bscs-dev-team/bscs-design-system-core'


type FilterByDropdownProps = {
  categoryFilters?: string[],
  items: Record<string, (string | boolean | string[])[]>,
  activeFilters: string[],
  setActiveFilters: (value: string[]) => void
}

/*
*
*
* NOTE: Don't name filter by field "type".  "type" is specific to ERC categories.
*
*
*/
const getSearchItems = (searchableElements: NodeListOf<Element>): string[][] => {
  let searchItems: string[][] = []

  for (let i: number = 0; i < searchableElements.length; i++) {
    let id: string | null = searchableElements[i].getAttribute('id')

    if (!id) {
      id = ''
    }

    const dataFilter: string | null = searchableElements[i].getAttribute('data-filter')

    let searchValues: string = ''
    if (dataFilter) {
      searchValues = JSON.parse(dataFilter)
    }

    searchItems.push([id, searchValues])
  }

  return searchItems
}

const displayElement = (elem: HTMLElement): true => {
  elem.style.display = ''

  if (elem && elem.parentElement) {
    elem.parentElement.style.display = ''
  }

  return true
}

const hideElement = (elem: HTMLElement): false => {
  elem.style.display = 'none'

  if (elem && elem.parentElement) {
    elem.parentElement.style.display = 'none'
  }

  return false
}

const checkIfItemInList = (item: string, listOfItems: string[]) => {
  return listOfItems.includes(item)
}

const getActiveFilter = (filters: string[], updatedFilters: string[]) => {
  for (let i = 0; i < filters.length; i++) {
    if (checkIfItemInList(filters[i], updatedFilters)) {
      return filters[i]
    }
  }

  return false
}

const loopThroughEachSearchableDOMElement = (resourceJson: any, updatedFilters: string[], elem: HTMLElement): boolean => {
  for (let key in resourceJson) {
    const currentFilter: any[] | string = resourceJson[key]
    if (
      Array.isArray(currentFilter)
      && currentFilter.length
      && key !== 'type'
      && getActiveFilter(currentFilter, updatedFilters) // Loops through each matchable value in a category of matchable values for each DOM element
    ) {
      return displayElement(elem) //: string[] show = true
    }

    if (
      typeof (currentFilter) === 'string'
      && checkIfItemInList(currentFilter, updatedFilters)
      && key !== 'type'
    ) {
      return displayElement(elem) //: string[] show = true
    }
  }

  return false
}

const handleDisplay = (
  searchItems: any[][],
  updatedFilters: string[],
  categoryFilters: string[] | undefined
) => {
  let activeCategoryFilter: string | false = false

  if (categoryFilters) {
    activeCategoryFilter = getActiveFilter(categoryFilters, updatedFilters)
  }

  //Loops through searchable elements in DOM
  for (let i: number = 0; i < searchItems.length; i++) {
    const resourceJson = searchItems[i][1]
    let elem: HTMLElement | null = (document.getElementById(searchItems[i][0]) as HTMLElement)

    if (!elem) continue

    //If a category filter exists, only show elements that have that filter as a type
    if (
      'type' in resourceJson
      && activeCategoryFilter
      && activeCategoryFilter !== resourceJson['type']
    ) {
      hideElement(elem)
      return
    //If only a category filter exists since if length is 1 and activeCategory exists the updatedFilters list must only be a category filter
    }

    if (
      'type' in resourceJson
      && activeCategoryFilter
      && activeCategoryFilter === resourceJson['type']
      && updatedFilters.length === 1
    ) {
      displayElement(elem)
      return
    //After category filter checks, decide if elements should be displayed if there are multiple filters
    //Elements should only display if they meet the category filter and at least one additional filter
    }

    //Loops through matchable values for each DOM element
    const show: boolean = loopThroughEachSearchableDOMElement(resourceJson, updatedFilters, elem)
    if (!show) {
      hideElement(elem)
    }
  }
}

//Displays all and stops execution if there are no filters
const displayAllElements = (searchItems: string[][], updatedFilters: string[]): boolean => {
  if (updatedFilters.length === 0) {
    for (let i: number = 0; i < searchItems.length; i++) {
      const elem: HTMLElement | null = (document.getElementById(searchItems[i][0]) as HTMLElement)
      displayElement(elem)
    }

    return true
  }

  return false
}

const removeFilter = (activeFilters: string[], filter: string): string[] => {
  const filterIndex = activeFilters.indexOf(filter)

  if (filterIndex === -1) {
    return activeFilters
  }

  // make shallow copy of activeFilters
  // remove 1 element at updated_filters[filter_index]
  const updatedFilters: string[] = activeFilters.slice(0).splice(filterIndex, 1)

  return updatedFilters
}

const handleFilter = (filter: string, activeFilters: string[]): string[] => {
  if (activeFilters.includes(filter)) {
    return removeFilter(activeFilters, filter)
  }

  return [...activeFilters, filter]
}

const renderFilterMenu = (
  items: Record<string, (string | boolean | string[])[]>
): string[] => {
  let menu: string[] = []

  for (let key in items) {
    // const categoryTitleElem: string = (items[key][0] as string)
    const categoryItemElems: string[] = (items[key][2] as string[])

    menu.push(...categoryItemElems)
  }

  return menu
}

const FilterByDropdown: FC<FilterByDropdownProps> = ({
  activeFilters,
  categoryFilters,
  items,
  setActiveFilters
}: FilterByDropdownProps) => {
  const [searchItems, setSearchItems] = useState<string[][]>([])

  //componentDidMount
  useEffect(() => {
    if (document) {
      const searchable_elements: NodeListOf<Element> = document.querySelectorAll('[data-filter]')

      setSearchItems(getSearchItems(searchable_elements))
    }
  }, [])

  // componentDidUpdate
  useEffect(() => {
    setActiveFilters(activeFilters)

    const display_all = displayAllElements(searchItems, activeFilters)

    if (display_all) {
      return
    }

    if (categoryFilters) {
      handleDisplay(searchItems, activeFilters, categoryFilters)
      return
    }

    handleDisplay(searchItems, activeFilters, undefined)
  }, [activeFilters])

  if (items) {
    return (
      <div id="filter">
        <FormDropdownField
          dropdownItems={renderFilterMenu(items)}
          setValue={(value: string) => {
            const updatedFilters: string[] = handleFilter(value, activeFilters)
            setActiveFilters(updatedFilters)
          }}
          title='Filter'
          visible={true}
        />
      </div>
    )
  }

  return <React.Fragment />
}

export default FilterByDropdown

export {
  getSearchItems,
  displayElement,
  hideElement,
  displayAllElements,
  removeFilter,
  handleFilter,
  getActiveFilter,
  checkIfItemInList
}
